import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { Link } from "gatsby";
import { trans } from "../../utils/constants";

import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import { AccountTypesQuery } from "../../prismic/staticQueries";
import BANNER from "../../images/siteImages/accounts/ACCOUNTS_BANNER.jpg";
import PaymentSlider from "../Company/PaymentSlider";

const AccountTypes = ({ language }) => {
  const accountTypesData = AccountTypesQuery(language);
  // accountTypesData.account_types.push(accountTypesData.account_types)
  return (
    <Layout>
      <BreadCrumbBanner
        heading={accountTypesData.page_heading}
        banner_image={BANNER}
      />
      <div className="my-5 text-center">
        {/* <h1 className="pt-5">{accountTypesData.page_heading.text}</h1> */}
      </div>
      <Container>
        <div className="account-comp">
          <div className="arrow arrow-left">
            <i className="fas fa-angle-left"></i>
          </div>
          <div className="arrow arrow-right">
            <i className="fas fa-angle-right"></i>
          </div>
          <Row className="account-types shadowed-container grey-dient">
            {accountTypesData.accounts_box.map((obj, index) => (
              <>
                {/* index ===0 && <>
                  <Col xs={12} className="accounts-header" >
                    <Row>
                      <Col xs={3} className="text-center">{''}</Col>
                      <Col xs={6} className="text-center">
                        {obj.text1[0] ? obj.text1.text: ''}
                      </Col>
                      <Col xs={3} className="text-center">
                        {obj.text2[0] ? obj.text2.text: ''}
                      </Col>
                    </Row>
                  </Col>
                </> */}

                {index === 1 && (
                  <Col xs={12} className="accounts-row" key={index}>
                    <Row>
                      <Col className="has-seperator py-2">
                        {obj.text1 ? obj.text1.text : ""}
                      </Col>
                      <Col className="has-seperator py-2 text-center has-blue-bg has-blue-text font-weight-bold">
                        {obj.text2 ? obj.text2.text : ""}
                      </Col>
                      <Col className="has-seperator py-2 text-center has-blue-bg has-blue-text font-weight-bold">
                        {obj.text3 ? obj.text3.text : ""}
                      </Col>
                      <Col className="has-seperator py-2 text-center has-blue-bg has-blue-text font-weight-bold">
                        {obj.text4 ? obj.text4.text : ""}
                      </Col>
                      <Col className="py-2 text-center has-blue-bg has-blue-text font-weight-bold">
                        {obj.text5 ? obj.text5.text : ""}
                      </Col>
                    </Row>
                  </Col>
                )}

                {index > 1 &&
                  index < accountTypesData.accounts_box.length - 1 && (
                    <>
                      <Col xs={12} className="accounts-row">
                        <Row>
                          <Col className="has-seperator py-2">
                            {obj.text1 ? obj.text1.text : ""}
                          </Col>
                          <Col className="has-seperator py-2 text-center">
                            {obj.text2 ? obj.text2.text : ""}
                          </Col>
                          <Col className="has-seperator py-2 text-center">
                            {obj.text3 ? obj.text3.text : ""}
                          </Col>
                          <Col className="has-seperator py-2 text-center">
                            {obj.text4 ? obj.text4.text : ""}
                          </Col>
                          <Col className="py-2 text-center">
                            {obj.text5 ? obj.text5.text : ""}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}

                {index > 1 &&
                  index === accountTypesData.accounts_box.length - 1 && (
                    <>
                      <Col xs={12} className="accounts-row">
                        <Row>
                          <Col>{""}</Col>
                          <Col className="has-orange-button text-center">
                            <PrismicRichText field={obj.text2.richText} />
                          </Col>
                          <Col className="has-orange-button text-center">
                            <PrismicRichText field={obj.text3.richText} />
                          </Col>
                          <Col className="has-orange-button text-center">
                            <PrismicRichText field={obj.text4.richText} />
                          </Col>
                          <Col className="has-orange-button text-center">
                            <PrismicRichText field={obj.text5.richText} />
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
              </>
            ))}
            {/* <Col>
            </Col> */}
          </Row>
        </div>

        <Row>
          <Col xs={12} className="text-center pt-5 pb-4 text-desc">
            {/* <PrismicRichText data={accountTypesData.extra_description} /> */}
            {accountTypesData.extra_description.text}
          </Col>

          <Col xs={12} className="text-center pb-5">
            <Link
              className="btn btn-submit-grey"
              to={"https://client.roxcapitals.com/register/forex/demo"}
            >
              {trans("tryDemo", language)}
              {/* Try Demo */}
            </Link>
          </Col>
        </Row>
      </Container>
      
    </Layout>
  );
};

const mapStateToProps = (state) => ({ language: state.language });

export default connect(mapStateToProps)(AccountTypes);
